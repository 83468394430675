import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Unauthorized from "../../unauthorized";
import axios from "axios";
import Async from "react-select/async";
import { getGroupList } from "../../../store/actionCreators/Groups/GroupsAction";
import FlatDiscountForm from "./FlatDiscountForm";
import ProductDiscount from "./ProductDiscount";
import PercentageDiscountForm from "./PercentageDiscountForm";
import AddonDiscount from "./AddonDiscount";
import AddNewGroupsCoupon from "./AddNewGroupsCoupon";
import { useMemo } from "react";
import { toast } from "react-toastify";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import LastForm from "./LastForm";
import LastFormPro from "./LastFormPro";
import { getAllCustomerRoles } from "../../../store/actionCreators/Customers/CustomerRolesActions";
import EditGroup from "./EditGroup";
import { useNavigate } from "react-router-dom";
import WalletCashbackFrom from "./WalletCashbackFrom";

const AddCoupon = ({
  editPermission,
  branchArray,
  groupArray,
  empArray,
  empObj,
  branchObj,
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const groups = useSelector((state) => state.groups);
  const cust = useSelector((state) => state.customersList);
  const { isLoading, setIsLoading } = useState(false);
  const [selectedCustomers, setCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [selectedBranches, setSelected] = useState([]);
  const [customerOption, setCustomerOption] = useState([]);
  const [options, setOptions] = useState([]);
  // from addons
  const [productFullData, setProductFullData] = useState([]);
  // const [sendGroups, setSendGroups] = useState([]);
  const navigate=useNavigate();
  const customerRoleData = useSelector((state) => state.customer_roles);

  const customerRolesList = customerRoleData?.data?.all_customer_roles || [];

  const [productArr, setProductArr] = useState([]);
  const [addonsArr, setAddonsArr] = useState([]);

  // console.log(productArr, "ddddd");

  useEffect(() => {
    dispatch(getAllCustomerRoles());
  }, []);
  

  const newProductData = useMemo(
    () => productFullData.filter(({ selected }) => selected),
    [productFullData]
  );

  const addOnData = useMemo(
    () => newProductData.map(({ addonData }) => addonData),
    [newProductData]
  );

  const employee = useSelector((state) => state.employees);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  useEffect(() => {
    if (employee.data) {
      if (employee.data.data) {
        setEmployeeOptions(
          employee.data.data.map((item) => ({
            label: `${item.full_name}(${item.mobile_no})`,
            value: item.employee_id,
          }))
        );
      }
    }
  }, [employee]);

  // useEffect(() => {

  // }, [newProductData]);
  // useEffect(() => {

  // }, [productFullData]);
  // useEffect(() => {

  // const addonsList = addOnData.map(
  //   (item) => item?.add_on?.add_on_options
  // );

  // const addonsList = addOnData.reduce((acc, curr) => {

  //   const li = curr.map((item) => {
  //     return item?.add_on?.add_on_options || [];
  //   });
  //   acc.push(li);
  //   return acc;
  // }, []);

  // }, [addOnData]);

  const dataForAPIProducts = useMemo(
    () =>
      productFullData
        .filter(({ selected }) => selected)
        .map(({ addonData: _, ...restFields }) => restFields),
    [productFullData]
  );

  // const [customerType, setCustomerType] = useState({
  //   label: "All Customers",
  //   value: "All",
  // });
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [walletCouponType, setWalletCouponType] = useState("");
  const [couponType, setCouponType] = useState("");
  const [uploadedImage, setUploadedIM] = useState(null);
  const [preview, setPreview] = useState();

  const [data, setData] = useState({
    // BACK FORM SE
    customer_type: "All",
    // BACK FORM SE
    discount_type: "",
    // CUSTOMER FORM SE
    customers: [],
    products: [],
    groups: "",
    title: "",
    description: "",
    code: "",
    branches: [],
    employees: [],
    customer_role: "All",
    start: "",
    end: "",
    min_cart_value: "",
    discount_value: 0,
    max_discount_value: 0,
    // boolean
    max_discount_valid: null,
    max_use_value: 0,
    // boolean
    max_use_valid: null,
    min_order_for_coup: 0,
    platform: "All",
    payment_type: "All",
    // rest all boolean
    combine: null,
    memb_apply: null,
    active: null,
    image:null
  });

  useEffect(() => {
    if (!uploadedImage) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(uploadedImage);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [uploadedImage]);

  useEffect(() => {
    // update Newproduct.image when uploadedImage changes
    setData((prevProduct) => ({
      ...prevProduct,
      image: uploadedImage,
    }));
  }, [uploadedImage]);

  const compressImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          if (width <= 600 && height <= 600) {
            width = 400;
            height = 400;
          } else if (width <= 800 && height <= 800) {
            width = 600;
            height = 600;
          } else if (width > maxWidth || height > maxHeight) {
            // Check if the image is already smaller than or equal to the desired dimensions
            const ratio = Math.max(width / maxWidth, height / maxHeight);
            width /= ratio;
            height /= ratio;
          }
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              console.log(`Original size: ${file.size} bytes`);
              console.log(`Compressed size: ${blob.size} bytes`);
              console.log(`Original dimensions: ${img.width} x ${img.height}`);
              console.log(`Compressed dimensions: ${width} x ${height}`);
              resolve(blob);
            },
            "image/png",
            0.6
          );
        };
        img.onerror = (err) => reject(err);
      };
    });
  };

  const imageUpload = async (event) => {
    const file = event.target.files[0];
    const compressedBlob = await compressImage(file, 800, 800);
    setUploadedIM(compressedBlob);
  };

  useEffect(() => {
    let array = [];
    if (branchArray) {
      array = branchArray.map((item, index) => {
        return { label: item.branch_name, value: item.branch_id };
      });
    } else array = [];
    setOptions(array);
  }, [branchArray]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  function getDropdownButtonLabel1({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  const displayBranch = () => {
    if (options) {
      return (
        <div className="categorySelect">
          <ReactMultiselectCheckboxes
            options={[{ label: "All", value: "*" }, ...options]}
            placeholderButtonLabel="Branches"
            getDropdownButtonLabel={getDropdownButtonLabel1}
            value={selectedBranches}
            onChange={onChange}
            setState={setSelected}
            required
          />
        </div>
      );
    }
  };

  const onChangeForFields = (field_name, value) => {
    const dataClone = { ...data };
    dataClone[field_name] = value;
    setData(dataClone);
  };

  let customerString = useMemo(() => {
    let customerString = "";
    for (let i = 0; i < selectedCustomers.length; i++) {
      if (i === selectedCustomers.length - 1) {
        customerString = customerString + selectedCustomers[i].value;
      } else {
        customerString = customerString + selectedCustomers[i].value + ",";
      }
    }

    return customerString;
  }, [selectedCustomers]);

  useEffect(() => {
    dispatch(getGroupList());
  }, []);

  useEffect(() => {
    if (groups.data) {
      if (groups.data.data) {
        setGroupOptions(
          groups.data.data.map((item) => ({
            label: item.customer_group_name,
            value: item.customer_group_name,
          }))
        );
      }
    }
  }, [groups]);

  useEffect(() => {
    if (cust.data) {
      if (cust.data.data) {
        setCustomerOption(
          cust.data.data.map((item) => ({
            label: `${item.first_name} ${item.last_name ? item.last_name : ""}(${item.mobile_no})`,
            value: item.customer_id,
          }))
        );
      }
    }
  }, [cust]);

  const customertypeOptions = [
    { label: "All Customers", value: "All" },
    { label: "Groups", value: "Groups" },
    { label: "Special Customers", value: "Special Customers" },
  ];

  const customerRolesNameOnly = customerRolesList.map((item, i) => {
    return {
      label: item.customer_type,

      value: item.customer_type,
    };
  }, []);

  const customerTypeOptionsWithRoles = customertypeOptions.concat(
    customerRolesNameOnly
  );

  const couponTypeOptions = [
    { label: "Flat Discount", value: "flat" },
    { label: "Percentage Discount", value: "percentage" },
    { label: "Product Discount", value: "product" },
    { label: "Add on Discount", value: "addon" },
    { label: "Wallet Cashback", value: "wallet" }
  ];

  const WalletCashbackType = [
    { label: "Flat", value: "flat" },
    { label: "Percentage", value: "percentage" },
  ];

  const handleChange = (entry) => (e) => {
    setData({ ...data, [entry]: e.target.value });
  };
 
  const customerTypeSelection = (
    <div>
      <label className="mb-2">Select Customer Type:</label>
      <Select
        className="mb-2"
        options={customerTypeOptionsWithRoles}
        // defaultValue={data.customer_type}
        value={{
          label: data.customer_type,
          value: data.customer_type,
        }}
        onChange={(e) => setData({ ...data, customer_type: e.value })}
      />
      <Button
          className="mb-2 mx-2"
          type="warning"
          onClick={() => {
            navigate('/coupons');
          }}
        >
          Back
        </Button>
      <Button
        className="mb-2"
        type="warning"
        onClick={() => {
          if (
            data.customer_type === "All" ||
            data.customer_type === "Platinum" ||
            data.customer_type === "Gold" ||
            data.customer_type === "General" ||
            data.customer_type === "Silver"
          )
            setStep(3);
          else setStep(2);
        }}
      >
        Next
      </Button>
    </div>
  );

  const loadCustomers = async (customer) => {
    // console.log(customer);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_customers/1?search=${customer}`
      );

      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.first_name} ${c.last_name ? c.last_name : ""}(${
            c.mobile_no
          })`,
          value: c.customer_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const loadData = async (emp) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_employees/1?search=${emp}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.full_name}(${c.mobile_no})`,
          value: c.employee_id,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const loadGroupData = async (group) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/search_groups/1?search=${group}`
      );
      if (res.data && res.data.data) {
        const data = res.data.data.map((c) => ({
          label: `${c.customer_group_name} `,
          value: c.customer_group_name,
        }));

        return Promise.resolve(data);
      }
    } catch (error) {
      console.log("error", error);
    }

    return Promise.resolve([]);
  };

  const step2 = {
    "Special Customers": (
      <div>
        <div className="mb-2" style={{ width: "20rem" }}>
          <label className="mb-2">Select Customers:</label>
          <Async
            isMulti={true}
            defaultOptions={customerOption}
            cacheOptions={[{ label: "1234", value: "1234" }]}
            isLoading={isLoading}
            value={selectedCustomers}
            loadOptions={(e) => loadCustomers(e)}
            onChange={(e) => setCustomers(e)}
            placeholder="Search by Name or Phone no...."
          />
        </div>
        <Button
          className="mb-2 mx-2"
          type="warning"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        <Button
          className="mb-2"
          type="warning"
          onClick={() => {
            setStep(3);
          }}
        >
          Next
        </Button>
      </div>
    ),

    Groups: (
      <div>
        <div className="d-flex gap-2">
          <Button type="warning" onClick={() => setShowModal(true)}>
            Add New Group
          </Button>
          <Button type="warning" onClick={() => setEditModal(true)}>
            Edit Group
          </Button>
          <AddNewGroupsCoupon
            customerOption={customerOption}
            show={showModal}
            close={() => setShowModal(false)}
          />
          <EditGroup groupOptions={groupOptions} customerOption={customerOption} show={showEditModal} close={() => setEditModal(false)} />
        </div>
        <div className="mb-2">
          <label className="mt-2 mb-2">Select a group:</label>
          <Async
            defaultOptions={groupOptions}
            cacheOptions={[{ label: "1234", value: "1234" }]}
            isLoading={isLoading}
            loadOptions={(e) => loadGroupData(e)}
            onChange={(e) => setSelectedGroup(e)}
            isMulti={true}
            placeholder='Search group...'
          />
          {/* {console.log(selectedGroup)} */}
        </div>
        <Button
          className="mb-2 mx-2"
          type="warning"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
        <Button
          className="mb-2"
          type="warning"
          onClick={() => {
            setStep(3);
          }}
        >
          Next
        </Button>
      </div>
    ),
  };

  const step3 = (
    <div>
      <label className="mb-2">Select Coupon Type:</label>
      <Select
        className="mb-2"
        options={couponTypeOptions}
        // defaultValue={data.discount_type}
        value={{
          label: couponType != "" ? couponType : "Select...",
          value: data.discount_type,
        }}
        onChange={(e) => {setData({ ...data, discount_type: e.value })
          setCouponType(e.label)
        }}
        required
      />
      {data.discount_type === "flat" && step === 3 ? (
        <Col className="mb-2">
          <label> Flat Discount Amount</label>
          <input
            onChange={(e) =>
              onChangeForFields("discount_value", e.target.value)
            }
            type="number"
            className="form-control"
            value={data.discount_value}
            required
            min='0'
          />
        </Col>
      ) : data.discount_type === "percentage" && step === 3 ? (
        <Col className="mb-2">
          <label>Percentage Discount </label>
          <input
            onChange={(e) =>
              onChangeForFields("discount_value", e.target.value)
            }
            type="number"
            className="form-control"
            value={data.discount_value}
            required
            min='0'
          />
        </Col>
      ) : (data.discount_type === "wallet" || data.discount_type === "wallet + Percentage" || data.discount_type === "wallet + Flat") && step === 3 ? (
      <>
        <Col>
          <label className="mb-2">Wallet Cashback Type</label>
          <Select
            className="mb-2"
            options={WalletCashbackType}
            // defaultValue={data.discount_type}
            value={{
              label: walletCouponType != "" ? walletCouponType : "Select...",
              value: walletCouponType,
            }}
            onChange={(e) => {setData({ ...data, discount_type: `wallet + ${e.label}`})
              setWalletCouponType(e.label)
            }}
            required
          />
        </Col>
        <Col className="mb-2">
          <label>Wallet Cashback {walletCouponType === "Percentage" ? 'Percent' : walletCouponType === "Flat" ? 'Amount' : ""}</label>
          <input
            onChange={(e) =>
              onChangeForFields("discount_value", e.target.value)
            }
            type="number"
            className="form-control"
            value={data.discount_value}
            required
            min='0'
          />
        </Col>
      </>
      ) : (
        ""
      )}
      <Button
        className="mb-2 mx-2"
        type="warning"
        onClick={() => {
          if (
            data.customer_type === "All" ||
            data.customer_type === "Platinum" ||
            data.customer_type === "Silver" ||
            data.customer_type === "General" ||
            data.customer_type === "Gold"
          ) {
            setStep(1);
          } else {
            setStep(step - 1);
          }
        }}
      >
        Back
      </Button>
      <Button
        className="mb-2"
        type="warning"
        onClick={() => {
          if (data.discount_type === "") {
            toast.error(`Please Select Coupon Type`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else setStep(4);
        }}
      >
        Next
      </Button>
    </div>
  );

  let { branchString } = useMemo(() => {
    let branchString = "";

    for (let i = 0; i < selectedBranches.length; i++) {
      if (selectedBranches[i].label === "All") {
        branchString = "All";
        break;
      } else if (i === selectedBranches.length - 1) {
        branchString = branchString + selectedBranches[i].value;
      } else {
        branchString = branchString + selectedBranches[i].value + ",";
      }
    }

    return { branchString };
  }, [selectedBranches]);

  let { empString } = useMemo(() => {
    let empString = "";

    for (let i = 0; i < employees.length; i++) {
      if (i === employees.length - 1) {
        empString = empString + employees[i].value;
      } else {
        empString = empString + employees[i].value + ",";
      }
    }

    return { empString };
  }, [employees]);

  let { groupString } = useMemo(() => {
    let groupString = "";

    for (let i = 0; i < selectedGroup.length; i++) {
      if (i === selectedGroup.length - 1) {
        groupString = groupString + selectedGroup[i].value;
      } else {
        groupString = groupString + selectedGroup[i].value + ",";
      }
    }

    return { groupString };
  }, [selectedGroup]);

  const handleFinalSubmit = (e) => {
    e.preventDefault();
    const start_date = new Date(data.start);
    const end_date = new Date(data.end);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (start_date < today || end_date < today) {
      toast.error(`You cannot select a past date.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if(end_date < start_date){
      toast.error(`The end date must be later than the start date.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (
      data.start !== "" &&
      data.end !== "" &&
      data.min_cart_value !== "" &&
      data.max_discount_valid !== null &&
      data.max_use_valid !== null &&
      data.combine !== null &&
      data.memb_apply !== null &&
      data.active !== null
    ) {
      const form = new FormData();
      form.append("image", data.image);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_new_coupon`,

          {
            ...data,
            addons: addOnData,
            products: dataForAPIProducts,
            customers: customerString,
            groups: groupString,
            branches: branchString,
            employees: empString,
          },
        )
        .then(
          (response) => {
            if (response?.data?.status === "success") {
              form.append("all_coupons_id", response?.data?.result?.all_coupons_id)
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_coupon_image`,

                  form,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                )
                .then((response) => {
                  if (response?.data?.status == "success") {
                    toast.success(`Successfully Added`, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 4000);
                  }
                })
            } else if (response.data.msg === "Coupon Already Exist!") {
              toast.error(`Coupon Already Exist!`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }else{
      toast.error(`Please fill the form completely`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFinalSubmitPro = (e) => {
    e.preventDefault();
    // console.log(employees,empString);

    const start_date = new Date(data.start);
    const end_date = new Date(data.end);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (start_date < today || end_date < today) {
      toast.error(`You cannot select a past date.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if(end_date < start_date){
      toast.error(`The end date must be later than the start date.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (
      data.start !== "" &&
      data.end !== "" &&
      data.min_cart_value !== "" &&
      data.max_discount_valid !== null &&
      data.max_use_valid !== null &&
      data.combine !== null &&
      data.memb_apply !== null &&
      data.active !== null
    ) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_new_coupon`,

            {
              ...data,

              products: productArr,
              customers: customerString,
              groups: groupString,
              branches: branchString,
              employees: empString,
            }
          )
          .then(
            (response) => {
              // console.log(response.data.data);
              if (response?.data?.status === "success") {
                const form = new FormData();
                form.append("image", data.image);
                form.append("all_coupons_id", response?.data?.result?.all_coupons_id)
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}api/v1/admin/all_coupons/add_coupon_image`,

                  form,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                )
                .then((response) => {
                  if (response?.data?.status == "success") {
                    toast.success(`Successfully Added`, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 4000);
                  }
                })
              } else if (response.data.msg === "Coupon Already Exist!") {
                toast.error(`Coupon Already Exist!`, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
    }
    else{
      toast.error(`Please fill the form completely`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // forms components render
  const RenderStep4 = () => {
    if (data.discount_type === "flat" && step === 4) {
      return (
        <FlatDiscountForm
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmit}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
          employeeOptions={employeeOptions}
          imageUpload={imageUpload}
          uploadedImage={uploadedImage}
          preview={preview}
        />
      );
    }else if ((data.discount_type === "wallet" || data.discount_type === "wallet + Percentage" || data.discount_type === "wallet + Flat") && step === 4) {
      return (
        <WalletCashbackFrom
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          setData={setData}
          walletCouponType={walletCouponType}
          setWalletCouponType={setWalletCouponType}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmit}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
          employeeOptions={employeeOptions}
          imageUpload={imageUpload}
          uploadedImage={uploadedImage}
          preview={preview}
        />
      );
    } else if (data.discount_type === "percentage" && step === 4) {
      return (
        <PercentageDiscountForm
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          handleChange={handleChange}
          handleFinalSubmit={handleFinalSubmit}
          displayBranch={displayBranch}
          empArray={empArray}
          empObj={empObj}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
          employeeOptions={employeeOptions}
          imageUpload={imageUpload}
          uploadedImage={uploadedImage}
          preview={preview}
        />
      );
    } else if (data.discount_type === "product" && step === 4) {
      return (
        <ProductDiscount
          step={step}
          setStep={setStep}
          setProductArr={setProductArr}
          employees={employees}
        />
      );
    } else if (data.discount_type === "addon" && step === 4) {
      return (
        <AddonDiscount
          step={step}
          setStep={setStep}
          setAddonsArr={setAddonsArr}
          productFullData={productFullData}
          setProductFullData={setProductFullData}
          employees={employees}

        />
      );
    } else return "";
  };

  // forms components render
  const renderStep5 = () => {
    if (step === 5 && data.discount_type === "addon") {
      return (
        <LastForm
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmit}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
          employeeOptions={employeeOptions}
          imageUpload={imageUpload}
          uploadedImage={uploadedImage}
          preview={preview}
        />
      );
    } else if (step === 5 && data.discount_type === "product") {
      return (
        <LastFormPro
          step={step}
          data={data}
          onChangeForFields={onChangeForFields}
          setStep={setStep}
          empArray={empArray}
          empObj={empObj}
          displayBranch={displayBranch}
          handleFinalSubmit={handleFinalSubmitPro}
          loadData={loadData}
          setEmployees={setEmployees}
          isLoading={isLoading}
          employees={employees}
          employeeOptions={employeeOptions}
          imageUpload={imageUpload}
          uploadedImage={uploadedImage}
          preview={preview}
        />
      );
    } else return "";
  };

  if (editPermission) {
    return (
      <div style={{ paddingBottom: "150px" }} className="page-content ">
        <Row>
          <Col className="col-12">
            <Card>
              <Card.Body>
                <div className="">
                  <Card.Title className="h4 mb-4 my-auto">
                    Add New Coupon
                  </Card.Title>
                  {step === 1 ? customerTypeSelection : ""}
                  {step === 2 && data.customer_type === "Special Customers"
                    ? step2["Special Customers"]
                    : data.customer_type === "Groups" && step === 2
                    ? step2["Groups"]
                    : ""}
                  {step === 3 && step3}

                  {RenderStep4()}
                  {renderStep5()}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AddCoupon;
